import React, { useEffect, useState } from 'react';
import './App.css';
import DianaBG from '../src/assets/diana-avatar.svg'
import Logo from '../src/assets/logo.svg'
import IABG from '../src/assets/IA_cutted.svg'
import LogoAL from '../src/assets/secom-white.svg'

function App() {
  const [counter, setCounter] = useState<string>("00:00:00:00");
  const targetDate = new Date('2024-11-11T12:00:00'); // Data final

  function startCounter() {
    const now: Date = new Date();
    const difference: number = targetDate.getTime() - now.getTime();

    if (difference <= 0) {
      return "00:00:00:00";
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);
    return `${String(days).padStart(2, '0')} dias, ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(startCounter());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div style={{
      backgroundColor: "#1269FB",
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        backgroundImage: `url(${IABG})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'space-between',
          paddingBottom: '1rem',
          marginLeft: '10rem'
        }}>
          <div style={{ flex: 2, justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
            <img src={Logo} width={250} height={250} />
            <div style={{ gap: 5, display: 'flex', flexDirection: 'column' }}>
              <span style={{
                color: '#fff',
                fontSize: '2.5rem'
              }}>
                Lançamento em
              </span>
              <div>
                <span style={{
                  color: '#fff',
                  fontSize: '3rem',
                  borderColor: '#5897FF',
                  borderRadius: '1rem',
                  padding: '0.5 rem'
                }}>{counter}</span>
              </div>
            </div>
          </div>
          <div style={{ flex: 1, display: 'flex', alignItems: 'end' }}>
            <img src={LogoAL} width={250} />
          </div>
        </div>
        <div style={{ display: 'flex', flex: 1, alignItems: 'end', justifyContent: 'center' }}>
          <img src={DianaBG} width={700} style={{ marginRight: '5rem' }} />
        </div>
      </div>
    </div>
  );
}

export default App;
